import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import ReactPlayer from "react-player";
import axios from "axios";

interface Subtitle {
    start: number;
    end: number;
    text: string;
}

const parseVttTimestamp = (timestamp: string): number => {
    const [hours, minutes, seconds] = timestamp.split(":");
    const [secs, ms] = seconds.split(".");
    return parseInt(hours, 10) * 3600 + parseInt(minutes, 10) * 60 + parseFloat(`${secs}.${ms}`);
};

const QuickEdit: React.FC = () => {
    const { userId, videoId } = useParams<{ userId: string; videoId: string }>();
    const [subtitles, setSubtitles] = useState<Subtitle[]>([]);
    const [currentSubtitleIndex, setCurrentSubtitleIndex] = useState<number | null>(null);
    const [streamUrl, setStreamUrl] = useState<string>("");
    const [subtitleUrl, setSubtitleUrl] = useState<string>("");
    const [playbackRate, setPlaybackRate] = useState<number>(1);
    const [isAutoScrollEnabled, setIsAutoScrollEnabled] = useState<boolean>(true);
    const playerRef = useRef<ReactPlayer>(null);
    const transcriptionRef = useRef<HTMLDivElement>(null);
    const lastManualScrollTimestampRef = useRef<number>(0);

    useEffect(() => {
        const stream_url = `https://dsebqouejkpw6.cloudfront.net/${userId}/${videoId}/index.m3u8`;
        const subtitle_url = `https://dsebqouejkpw6.cloudfront.net/${userId}/${videoId}/subtitles.vtt`;
        setStreamUrl(stream_url);
        setSubtitleUrl(subtitle_url);

        const fetchSubtitles = async () => {
            try {
                const response = await axios.get(subtitle_url);
                const vttText = response.data.trim();
                const vttLines = vttText.split("\n\n");

                const parsedSubtitles: Subtitle[] = vttLines
                    .map((vttLine: string): Subtitle | null => {
                        const lines = vttLine.split("\n");
                        if (lines.length < 2) return null;

                        const timeLine = lines[1];
                        const [startTimestamp, endTimestamp] = timeLine.split(" --> ");
                        const text = lines.slice(2).join(" ");

                        if (!startTimestamp || !endTimestamp || !text) return null;

                        return {
                            start: parseVttTimestamp(startTimestamp),
                            end: parseVttTimestamp(endTimestamp),
                            text,
                        };
                    })
                    .filter((subtitle: Subtitle | null): subtitle is Subtitle => subtitle !== null);

                setSubtitles(parsedSubtitles);
            } catch (error) {
                console.error("Error fetching or parsing subtitles", error);
            }
        };

        fetchSubtitles();
    }, [userId, videoId]);

    const handleProgress = ({ playedSeconds }: { playedSeconds: number }) => {
        const index = subtitles.findIndex(
            subtitle => playedSeconds >= subtitle.start && playedSeconds <= subtitle.end
        );

        setCurrentSubtitleIndex(index);

        if (isAutoScrollEnabled && transcriptionRef.current) {
            const activeElement = transcriptionRef.current.querySelector(`[data-index="${index}"]`);
            if (activeElement) {
                activeElement.scrollIntoView({ behavior: "smooth", block: "center" });
            }
        }

        if (!isAutoScrollEnabled && transcriptionRef.current) {
            const activeElement = transcriptionRef.current.querySelector(`[data-index="${index}"]`);
            if (activeElement) {
                const elementRect = activeElement.getBoundingClientRect();
                const containerRect = transcriptionRef.current.getBoundingClientRect();
                if (
                    elementRect.top >= containerRect.top &&
                    elementRect.bottom <= containerRect.bottom
                ) {
                    setIsAutoScrollEnabled(true);
                }
            }
        }
    };

    const handleSubtitleClick = (index: number) => {
        const player = playerRef.current;
        if (player) {
            player.seekTo(subtitles[index].start, "seconds");
        }
    };

    const handlePlaybackRateChange = (rate: number) => {
        setPlaybackRate(rate);
    };

    const handleScroll = () => {
        const now = Date.now();
        if (now - lastManualScrollTimestampRef.current > 100) {
            lastManualScrollTimestampRef.current = now;
            if (isAutoScrollEnabled) {
                setIsAutoScrollEnabled(false);
            }
        }
    };

    const handleJumpBack = () => {
        setIsAutoScrollEnabled(true);

        if (currentSubtitleIndex !== null && transcriptionRef.current) {
            const activeElement = transcriptionRef.current.querySelector(
                `[data-index="${currentSubtitleIndex}"]`
            );
            if (activeElement) {
                activeElement.scrollIntoView({ behavior: "smooth", block: "center" });
            }
        }
    };

    return (
        <div className="flex flex-col md:flex-row h-[calc(100vh-theme(space.28))] px-4 md:px-24 py-4 space-y-4 md:space-y-0 md:space-x-4">
            <div className="md:w-1/3 flex flex-col bg-gray-100 p-4 rounded-md shadow-lg">
                <p className="text-xl font-semibold mb-4">Transcription</p>
                <div
                    ref={transcriptionRef}
                    className="flex-grow overflow-y-auto"
                    onScroll={handleScroll}
                >
                    <div className="space-y-2">
                        {subtitles.map((subtitle, index) => (
                            <p
                                key={index}
                                data-index={index}
                                onClick={() => handleSubtitleClick(index)}
                                className={`p-2 rounded-md cursor-pointer ${
                                    index === currentSubtitleIndex
                                        ? "bg-blue-500 text-white font-bold"
                                        : "bg-white text-gray-800"
                                }`}
                            >
                                {subtitle.text}
                            </p>
                        ))}
                    </div>
                </div>
                <button
                    onClick={handleJumpBack}
                    className={`mt-4 p-2 text-white rounded-md bg-slate-500 hover:bg-slate-600`}
                >
                    Jump Back to Current
                </button>
            </div>

            <div className="md:w-2/3 flex flex-col items-center justify-center space-y-4">
                <ReactPlayer
                    ref={playerRef}
                    url={streamUrl}
                    controls
                    width="100%"
                    height="auto"
                    playing
                    playbackRate={playbackRate}
                    onProgress={handleProgress}
                />

                <div>
                    <p className="font-medium">Playback Speed</p>
                </div>
                <div className="flex space-x-2">
                    {[0.25, 0.5, 1, 1.25, 1.5, 2].map(rate => (
                        <button
                            key={rate}
                            className={`btn ${playbackRate === rate ? "btn-active" : ""}`}
                            onClick={() => handlePlaybackRateChange(rate)}
                        >
                            {rate}x
                        </button>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default QuickEdit;
