import React, { useState, useEffect } from "react";

interface DropdownItem {
    label: string;
    value: string;
}

interface DropdownProps {
    items: DropdownItem[];
    onSelect: (value: string) => void;
    selected?: string;
}

const Dropdown: React.FC<DropdownProps> = ({ items, onSelect, selected }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState<DropdownItem | null>(null);

    useEffect(() => {
        if (selected) {
            const item = items.find(item => item.value === selected);
            setSelectedItem(item || null);
        }
    }, [items, selected]);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleItemClick = (item: DropdownItem) => {
        setSelectedItem(item);
        onSelect(item.value);
        setIsOpen(false);
    };

    return (
        <div className="relative inline-block text-left w-full">
            <div>
                <button
                    type="button"
                    className="inline-flex justify-between w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none"
                    onClick={toggleDropdown}
                >
                    {selectedItem ? selectedItem.label : "Select a collection"}
                    <svg
                        className="-mr-1 ml-2 h-5 w-5"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                    >
                        <path
                            fillRule="evenodd"
                            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                            clipRule="evenodd"
                        />
                    </svg>
                </button>
            </div>
            {isOpen && (
                <div className="origin-top-right absolute right-0 mt-2 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                    <div className="py-1 max-h-60 overflow-auto">
                        {items.map(item => (
                            <a
                                key={item.value}
                                href="#"
                                className={`${
                                    item === selectedItem
                                        ? "bg-gray-100 text-gray-900"
                                        : "text-gray-700"
                                } block px-4 py-2 text-sm hover:bg-gray-100`}
                                onClick={() => handleItemClick(item)}
                            >
                                {item.label}
                            </a>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default Dropdown;
