import React from "react";
import { Route, Routes } from "react-router-dom";
import { ProtectedRoute } from "./ProtectedRoute";

import Home from "../pages/Home";
import NotFound from "../pages/NotFound";
import Welcome from "../pages/Welcome";
import Authentication from "../pages/Login";
import Collections from "../pages/Collections";
import About from "../pages/About";
import CollectionDetail from "../pages/CollectionDetail";
import Search from "../pages/Search";
import ChangePassword from "../pages/changePassword";
import SignUp from "../pages/Signup";
import Verification from "../pages/verification";

import CompanyDashboard from "../pages/CompanyDashboard";
import InvitedUserOnboarding from "../pages/InvitedUserOnboarding";

import QuickEdit from "../pages/QuickEdit";

const AppRoutes: React.FC = () => {
    return (
        <Routes>
            <Route path="/" element={<Welcome />} />
            <Route
                path="/home"
                element={
                    <ProtectedRoute>
                        <Home />
                    </ProtectedRoute>
                }
            />
            <Route path="/login" element={<Authentication />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/verification" element={<Verification />} />
            <Route
                path="/collections"
                element={
                    <ProtectedRoute>
                        <Collections />
                    </ProtectedRoute>
                }
            />
            <Route path="/change-password" element={<ChangePassword />} />
            <Route path="/about" element={<About />} />

            <Route
                path="/collections/:id"
                element={
                    <ProtectedRoute>
                        <CollectionDetail />
                    </ProtectedRoute>
                }
            />
            <Route
                path="/collections/:id/search"
                element={
                    <ProtectedRoute>
                        <Search />
                    </ProtectedRoute>
                }
            />

            <Route
                path="/company"
                element={
                    <ProtectedRoute>
                        <CompanyDashboard />
                    </ProtectedRoute>
                }
            />

            <Route path="/onboarding" element={<InvitedUserOnboarding />} />
            <Route
                path="/quick-edit/:userId/:videoId"
                element={
                    <ProtectedRoute>
                        <QuickEdit />
                    </ProtectedRoute>
                }
            />

            <Route path="*" element={<NotFound />} />
        </Routes>
    );
};

export default AppRoutes;
