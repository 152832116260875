import React from "react";
import { useNavigate } from "react-router-dom";

import { parseDuration } from "../utils/duration";
import { parseDateTime } from "../utils/date";

import { AiFillVideoCamera, AiFillClockCircle, AiOutlineMore, AiFillDelete } from "react-icons/ai";
import { MdVideoLibrary } from "react-icons/md";

import blank from "../../assets/blank.jpg";

export interface collection {
    id: number;
    name: string;
    user_id: number;
    created_at: string;
    num_videos: number;
    total_duration: number;
    latest_thumbnail: string;
    latest_video_id: string;
}

const parseVideoNumber = (num_videos: number) => {
    return num_videos === 1 ? `${num_videos} video` : `${num_videos} videos`;
};

interface IndexTileProps {
    collection: collection;
    onDelete: (collection: collection) => void;
}

const IndexTile: React.FC<IndexTileProps> = ({ collection, onDelete }) => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate(`/collections/${collection.id}`);
    };

    const renderThumbnail = () => {
        if (collection.num_videos === 0) {
            return (
                <div className="flex justify-center items-center h-56 w-full bg-gray-300 cursor-pointer">
                    <MdVideoLibrary className="text-gray-500" size={48} />
                </div>
            );
        }

        const thumbnail = `https://zwive.s3.us-west-1.amazonaws.com/${collection.user_id}/thumbnails/${collection.latest_video_id}/8.jpg`;

        return (
            <img
                src={thumbnail}
                alt="thumbnail"
                onError={e => {
                    const target = e.target as HTMLImageElement;
                    target.onerror = null;
                    target.src = blank;
                }}
                onClick={handleClick}
                className="cursor-pointer h-56 object-cover w-full"
            />
        );
    };

    return (
        <div className="card card-compact xl:w-96 bg-base-100 outline outline-gray-200 hover:shadow-md">
            <figure onClick={handleClick}>{renderThumbnail()}</figure>
            <div className="card-body">
                <h2 className="card-title hover:underline cursor-pointer" onClick={handleClick}>
                    {collection.name}
                </h2>
                <div className="flex items-center gap-3">
                    <AiFillVideoCamera className="inline" size={16} color="gray" />
                    <p>
                        {parseVideoNumber(collection.num_videos)} (
                        {parseDuration(collection.total_duration)})
                    </p>
                </div>
                <div className="flex items-center gap-3">
                    <AiFillClockCircle className="inline" size={16} color="gray" />
                    <p>Created on {parseDateTime(collection.created_at)}</p>
                </div>
                <div className="card-actions justify-between items-center">
                    <div className="dropdown dropdown-start">
                        <AiOutlineMore
                            tabIndex={0}
                            className="cursor-pointer hover:bg-slate-100 rounded-full p-1"
                            size={26}
                            color="black"
                        />
                        {collection.user_id === Number(localStorage.getItem("user_id")) && (
                            <ul
                                tabIndex={0}
                                className="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52"
                            >
                                <li>
                                    <button onClick={() => onDelete(collection)}>
                                        <AiFillDelete className="inline" size={16} color="red" />
                                        Delete
                                    </button>
                                </li>
                            </ul>
                        )}
                    </div>
                    <button className="btn btn-primary text-white" onClick={handleClick}>
                        Explore
                    </button>
                </div>
            </div>
        </div>
    );
};

export default IndexTile;
