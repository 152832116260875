import moment from "moment";

export const parseDateTime = (date: string) => {
    // Example date: 2024-03-17T00:31:34.628Z
    const dateTime = moment(date);
    const formattedDateTime = dateTime.format("MMMM D, YYYY");
    return formattedDateTime;
};

export const formatDate = (date: string) => {
    const dateTime = moment(date);
    const formattedDateTime = dateTime.format(" h:mm A, MMMM D");
    return formattedDateTime;
};
