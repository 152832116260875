import React, { useEffect, useState, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";

import Modal from "../components/Modal";

import VideoTile from "../components/VideoTile/DefaultVideoTile";
import { Video } from "../components/VideoTile/DefaultVideoTile";

import {
    AiOutlineDelete as DeleteIcon,
    AiOutlineSearch,
    AiOutlineUpload,
    AiOutlineDownload,
    AiOutlineCloudUpload,
    AiOutlineFile,
    AiOutlineClose,
    AiOutlineWarning,
} from "react-icons/ai";
import ReactPlayer from "react-player";

export const handleSubtitleDownload = async (subtitle_url: string) => {
    try {
        const response = await axios.get(subtitle_url, {
            responseType: "blob",
        });
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "subtitle.vtt");
        document.body.appendChild(link);
        link.click();
    } catch (error) {
        console.error(error);
    }
};

const CollectionDetail: React.FC = () => {
    const { id } = useParams();

    const userId = localStorage.getItem("user_id");

    const navigate = useNavigate();

    const token = localStorage.getItem("token");

    const [isLoading, setIsLoading] = useState<boolean>(true);

    const [name, setName] = useState<string>();
    const [videos, setVideos] = useState<Video[]>([]);

    const [showModal, setShowModal] = useState<boolean>(false);
    const [uploading, setUploading] = useState<boolean>(false);
    const [progress, setProgress] = useState<number[]>([]);
    const [error, setError] = useState<string>();

    const [selectedFiles, setSelectedFiles] = useState<File[]>([]);

    const [showPlayModal, setShowPlayModal] = useState<boolean>(false);
    const [selectedVideo, setSelectedVideo] = useState<Video>();

    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [videoToDelete, setVideoToDelete] = useState<Video | null>(null);

    const handleFileChange = (event: any) => {
        setSelectedFiles(Array.from(event.target.files));
    };

    const handleModal = () => {
        setShowModal(!showModal);
    };

    const fileInputRef = useRef<HTMLInputElement>(null);

    const handleChooseFiles = () => {
        fileInputRef.current?.click();
    };

    const removeSelectedFile = (index: number) => {
        setSelectedFiles(prevFiles => prevFiles.filter((_, i) => i !== index));
        setProgress(prevProgress => prevProgress.filter((_, i) => i !== index));
    };

    const fetchDetails = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API}/api/indexes/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            const hasPending = response.data.videos.some(
                (video: Video) => video.status !== "SUCCESS" && video.status !== "ERROR"
            );

            setName(response.data.collection);
            setVideos(response.data.videos);

            if (hasPending) {
                setTimeout(fetchDetails, 5000);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchDetails();
    }, [id, token, userId]);

    const isCollectionOwner = () => {
        if (!userId) {
            return false;
        }

        if (videos.length === 0) {
            return true;
        }

        if (userId === videos[0].user_id.toString()) {
            return true;
        }

        return false;
    };

    const uploadVideo = async () => {
        if (!selectedFiles.length) return;
        setUploading(true);
        const uploadProgress: number[] = new Array(selectedFiles.length).fill(0);
        setProgress(uploadProgress);

        try {
            await Promise.all(
                selectedFiles.map(async (file, index) => {
                    const extension = file.name.split(".").pop();

                    // Start upload for each file
                    const response = await axios.post(
                        `${process.env.REACT_APP_API}/api/upload`,
                        {
                            index_id: id,
                            file_type: file.type,
                            file_extension: extension,
                        },
                        {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                        }
                    );

                    const { url, file_id, download_url } = response.data;
                    const formData = new FormData();

                    Object.entries(url.fields).forEach(([key, value]) => {
                        if (typeof value === "string") {
                            formData.append(key, value);
                        }
                    });

                    formData.append("file", file);

                    await axios.post(url.url, formData, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                        onUploadProgress: progressEvent => {
                            const total = progressEvent.total ? progressEvent.total : 1;
                            const percentCompleted = Math.round(
                                (progressEvent.loaded * 100) / total
                            );
                            uploadProgress[index] = percentCompleted;
                            setProgress([...uploadProgress]);
                        },
                    });

                    // After upload, add record for each file
                    await axios.post(
                        `${process.env.REACT_APP_API}/api/add_records`,
                        {
                            index_id: id,
                            file_id,
                            file_name: file.name,
                            file_extension: extension,
                            file_size: file.size,
                            file_url: download_url,
                        },
                        {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                        }
                    );
                })
            );

            fetchDetails();
        } catch (error) {
            console.error("Upload failed:", error);
            setError("Upload failed");
        } finally {
            setUploading(false);
            setSelectedFiles([]);
            setProgress([]);
            setShowModal(false);
        }
    };

    const deleteVideo = async (file_id: string, url: string) => {
        try {
            await axios.post(
                `${process.env.REACT_APP_API}/api/delete_video`,
                {
                    index_id: id,
                    id: file_id,
                    file_extension: url.split(".").pop(),
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
        } catch (error: any) {
            console.error(error);
        } finally {
            setShowPlayModal(false);
            fetchDetails();
        }
    };

    const handleDeleteClick = (video: Video) => {
        setVideoToDelete(video);
        setShowDeleteModal(true);
    };

    const confirmDelete = async () => {
        if (videoToDelete) {
            await deleteVideo(videoToDelete.id, videoToDelete.file_url);
            setShowDeleteModal(false);
            setVideoToDelete(null);
            setShowPlayModal(false);
        }
    };

    const handleVideoSelect = (video: Video) => {
        setSelectedVideo(video);
        setShowPlayModal(!showPlayModal);
    };

    const handleVideoDownload = async (video_id: string, file_name: string) => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API}/api/generate-download-url/${video_id}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            const downloadUrl = response.data.url;

            const anchor = document.createElement("a");
            anchor.href = downloadUrl;
            anchor.download = file_name;
            document.body.appendChild(anchor);
            anchor.click();
            document.body.removeChild(anchor);
        } catch (error) {
            console.error("Failed to generate download URL:", error);
        }
    };

    const handleQuickEdit = (video: Video) => {
        navigate(`/quick-edit/${video.user_id}/${video.id}`);
    };

    const EmptyState = () => (
        <div className="flex flex-col items-center justify-center h-64 bg-gray-100 rounded-lg">
            <AiOutlineCloudUpload className="text-6xl text-gray-400 mb-4" />
            <h2 className="text-2xl font-semibold text-gray-700 mb-2">No videos yet</h2>
            <p className="text-gray-500 mb-4">Upload your first video to get started!</p>
            {isCollectionOwner() && (
                <button className="btn btn-primary text-white" onClick={handleModal}>
                    <AiOutlineUpload size={18} className="mr-2" />
                    Upload Video
                </button>
            )}
        </div>
    );

    return (
        <div className="h-screen px-24">
            {isLoading ? (
                <div className="flex justify-center items-center mt-[100px]">
                    <span className="loading loading-spinner loading-lg text-primary"></span>
                </div>
            ) : (
                <div>
                    <div className="flex justify-center items-center mb-10">
                        <div className="flex flex-col justify-center w-full">
                            <h1 className="text-4xl font-bold">{name}</h1>
                            <p className="text-lg">Upload or Search videos in the collection.</p>
                        </div>

                        <div className="flex justify-end gap-5">
                            <button
                                className="btn bg-gray-200"
                                onClick={() => navigate(`/collections/${id}/search`)}
                            >
                                <AiOutlineSearch size={18} />
                                Search
                            </button>
                            {isCollectionOwner() && (
                                <button
                                    className="btn btn-primary text-white"
                                    onClick={handleModal}
                                >
                                    <AiOutlineUpload size={18} />
                                    Upload
                                </button>
                            )}
                        </div>
                    </div>

                    <div></div>

                    <div>
                        {videos.length > 0 ? (
                            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-4">
                                {videos.map(video => (
                                    <VideoTile
                                        key={video.id}
                                        video={video}
                                        handleVideoSelect={handleVideoSelect}
                                        handleQuickEdit={handleQuickEdit}
                                        handleSubtitleDownload={() =>
                                            handleSubtitleDownload(video.subtitle_url)
                                        }
                                        handleVideoDownload={() =>
                                            handleVideoDownload(video.id, video.file_name)
                                        }
                                        handleVideoDelete={handleDeleteClick}
                                    />
                                ))}
                            </div>
                        ) : (
                            <EmptyState />
                        )}
                    </div>
                </div>
            )}

            {/* Below modal is a fallback to if I find any errors in the new modal! */}

            {/* <Modal isOpen={showModal} onClose={handleModal} title="Upload Files">
                <div className="flex flex-col gap-4">
                    <input type="file" onChange={handleFileChange} multiple />
                    <div className="mt-4">
                        {selectedFiles.map((file, index) => (
                            <div key={index} className="flex justify-between items-center mb-2">
                                <span className="text-sm">{file.name}</span>
                                {uploading && (
                                    <div className="flex gap-2 items-center">
                                        <progress
                                            className="progress progress-primary w-56"
                                            value={progress[index]}
                                            max="100"
                                        ></progress>
                                        <span className="text-sm">{progress[index]}%</span>
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                    {uploading && (
                        <div className="mt-4">
                            <p className="text-blue-500 text-center">Uploading...</p>
                        </div>
                    )}
                    <div className="flex justify-end gap-4 mt-4">
                        <button className="btn bg-slate-200" onClick={handleModal}>
                            Cancel
                        </button>
                        {selectedFiles.length > 0 && (
                            <button className="btn btn-primary text-white" onClick={uploadVideo}>
                                Upload
                            </button>
                        )}
                    </div>
                </div>
            </Modal> */}

            <Modal isOpen={showModal} onClose={handleModal} title="Upload Files">
                <div className="flex flex-col items-center gap-6 p-6">
                    <div className="text-center">
                        <AiOutlineCloudUpload className="text-6xl text-primary mx-auto mb-4" />
                        <h2 className="text-2xl font-semibold mb-2">Upload Your Videos</h2>
                        <p className="text-gray-600">
                            Choose video files to add to your collection
                        </p>
                    </div>

                    <input
                        type="file"
                        ref={fileInputRef}
                        onChange={handleFileChange}
                        multiple
                        className="hidden"
                        accept="video/*"
                    />

                    <button
                        className="btn btn-primary text-white px-8 text-lg"
                        onClick={handleChooseFiles}
                    >
                        <AiOutlineFile className="mr-2" />
                        Choose Files
                    </button>

                    {selectedFiles.length > 0 && (
                        <div className="w-full">
                            <h3 className="font-semibold mb-2">Selected Files:</h3>
                            {selectedFiles.map((file, index) => (
                                <div
                                    key={index}
                                    className="flex justify-between items-center mb-2 bg-gray-100 p-2 rounded-lg"
                                >
                                    <span className="text-sm truncate max-w-[300px] ml-2">
                                        {file.name}
                                    </span>
                                    <div className="flex items-center">
                                        {uploading ? (
                                            <div className="flex items-center">
                                                <progress
                                                    className="progress progress-primary w-24 mr-2"
                                                    value={progress[index]}
                                                    max="100"
                                                ></progress>
                                                <span className="text-xs w-12 text-right">
                                                    {progress[index]}%
                                                </span>
                                            </div>
                                        ) : (
                                            <button
                                                className="btn btn-ghost rounded-full"
                                                onClick={() => removeSelectedFile(index)}
                                            >
                                                <AiOutlineClose
                                                    className="text-black-500"
                                                    size={16}
                                                />
                                            </button>
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}

                    {uploading && (
                        <p className="text-primary font-semibold">Uploading... Please wait.</p>
                    )}

                    <div className="flex justify-end gap-4 w-full">
                        <button className="btn btn-ghost" onClick={handleModal}>
                            Cancel
                        </button>
                        {selectedFiles.length > 0 && !uploading && (
                            <button className="btn btn-primary text-white" onClick={uploadVideo}>
                                <AiOutlineUpload className="mr-2" />
                                Upload
                            </button>
                        )}
                    </div>
                </div>
            </Modal>

            <Modal
                isOpen={showPlayModal}
                onClose={() => setShowPlayModal(false)}
                title={selectedVideo?.file_name}
            >
                <div className="flex flex-col gap-4">
                    {selectedVideo && selectedVideo.stream_url && (
                        <ReactPlayer
                            url={selectedVideo.stream_url}
                            controls
                            width="100%"
                            height="100%"
                            playing
                            config={{
                                file: {
                                    attributes: {
                                        crossOrigin: "anonymous",
                                    },
                                    tracks: selectedVideo.subtitle_url
                                        ? [
                                              {
                                                  kind: "subtitles",
                                                  src: selectedVideo.subtitle_url,
                                                  srcLang: "en",
                                                  label: "English",
                                                  default: true,
                                              },
                                          ]
                                        : [],
                                },
                            }}
                        />
                    )}
                </div>

                <div className="flex justify-between w-full mt-4">
                    <DeleteIcon
                        className="text-3xl cursor-pointer hover:bg-slate-100 rounded-full mt-2 justify-center"
                        onClick={() => handleDeleteClick(selectedVideo!)}
                    />

                    <button
                        className="btn btn-ghost "
                        onClick={() => {
                            handleVideoDownload(selectedVideo!.id, selectedVideo!.file_url);
                        }}
                    >
                        <AiOutlineDownload size={20} />
                        Download
                    </button>
                </div>
            </Modal>

            <Modal
                isOpen={showDeleteModal}
                onClose={() => setShowDeleteModal(false)}
                title="Confirm Delete"
            >
                <div className="flex flex-col items-center gap-4 p-6">
                    <AiOutlineWarning className="text-6xl text-yellow-500" />
                    <h2 className="text-xl font-semibold text-center">
                        Are you sure you want to delete this video?
                    </h2>
                    <p className="text-gray-600 text-center">
                        Deleting this video will not reset your minutes used. This action cannot be
                        undone.
                    </p>
                    <div className="flex justify-center gap-4 mt-4">
                        <button className="btn btn-ghost" onClick={() => setShowDeleteModal(false)}>
                            Cancel
                        </button>
                        <button className="btn btn-error text-white" onClick={confirmDelete}>
                            Confirm Delete
                        </button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default CollectionDetail;
